import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import PropTypes from "prop-types";
import moment from "moment";

const useStyles = makeStyles(() => ({
  bodyItem: {
    padding: "5px !important",
    textAlign: "left",
    "& p": {
      fontSize: 10,
    },
    border: "none !important",
  },
  bodyRow: {
    minHeight: 35,
    transition: "all .3s",
    "&:hover": {
      background: "#f0f0f3",
    },
    border: "1px solid black",
    justifyContent: "space-around",
    alignItems: "center",
    overflow: "auto",
    maxHeight: "140px",
    whiteSpace: "normal",
  },
  tdItem: {
    border: "none !important",
    textAlign: "center",
    verticalAlign: "middle",
  },
}));

const TableFailedTransactionsTableItem = ({
  Id,
  Paid,
  InternalAccountId,
  Status,
  CurrencyCode,
  AmountGross,
  handleCheck,
  TransactionId,
  ListingId,
  OrderId,
  ReceiptId,
  checkboxes,
  ProviderAccountName,
  CustomerEmail,
  CustomerName,
  Title,
  UploadRetries,
  UploadAttempt,
  Error,
}) => {
  const classes = useStyles();
  return (
    <tr
      className={classes.bodyRow}
      style={{
        background: UploadAttempt >= UploadRetries ? "#edb7b7" : "#ffd9ae",
      }}
    >
      <td className={classes.bodyItem}>
        <Grid item style={{ textAlign: "center" }}>
          <Checkbox
            style={{ padding: 0 }}
            color="primary"
            checked={checkboxes?.[Id] || false}
            inputProps={{ "aria-label": "secondary checkbox" }}
            onChange={(e) => handleCheck(e, Id)}
          />
        </Grid>
      </td>
      <td className={classes.bodyItem}>
        <Grid item >
          <Typography variant="body2">
            {moment(Paid).format("YYYY-MM-DD HH:mm:ss Z")}
          </Typography>
        </Grid>
      </td>
      <td className={classes.bodyItem}>
        <Grid item >
          <Typography variant="body2">{InternalAccountId}</Typography>
        </Grid>
      </td>
      <td className={classes.bodyItem}>
        <Grid item  style={{ textAlign: "center" }}>
          <Typography variant="body2">{Status}</Typography>
        </Grid>
      </td>
      <td className={classes.bodyItem}>
        <Grid item >
          <Typography variant="body2">{TransactionId}</Typography>
        </Grid>
      </td>
      <td className={classes.bodyItem}>
        <Grid item >
          <Typography variant="body2">{ListingId}</Typography>
        </Grid>
      </td>
      <td className={classes.bodyItem}>
        <Grid item >
          <Typography variant="body2">{OrderId}</Typography>
        </Grid>
      </td>
      <td className={classes.bodyItem}>
        <Grid item >
          <Typography variant="body2">{ReceiptId}</Typography>
        </Grid>
      </td>
      <td className={classes.bodyItem}>
        <Grid item >
          <Typography
            variant="body2"
            style={{
              textAlign: "left",
              color: Number(AmountGross) > 0 ? "green" : "red",
            }}
          >
            {AmountGross + " " + CurrencyCode}{" "}
          </Typography>
        </Grid>
      </td>
      <td className={classes.bodyItem}>
        <Grid item  style={{ textAlign: "center" }}>
          <Typography variant="body2">{ProviderAccountName}</Typography>
        </Grid>
      </td>
      <td className={classes.bodyItem}>
        <Grid item  style={{ textAlign: "center" }}>
          <Typography variant="body2">{CustomerEmail}</Typography>
        </Grid>
      </td>
      <td className={classes.bodyItem}>
        <Grid item >
          <Typography variant="body2">{CustomerName}</Typography>
        </Grid>
      </td>
      <td className={classes.bodyItem}>
        <Grid item >
          <Typography variant="body2" title={Title}>
            {Title}
          </Typography>
        </Grid>
      </td>
      <td className={classes.bodyItem}>
        <Grid item  style={{ textAlign: "center" }}>
          <Typography variant="body2">
            {UploadAttempt} of {UploadRetries}
          </Typography>
        </Grid>
      </td>
      <td className={classes.bodyItem}>
        <Grid item >
          <Typography variant="body2">{Error}</Typography>
        </Grid>
      </td>
    </tr>
  );
};

TableFailedTransactionsTableItem.propTypes = {
  Id: PropTypes.string,
  Paid: PropTypes.string,
  Status: PropTypes.string,
  CurrencyCode: PropTypes.string,
  AmountGross: PropTypes.number,
  handleCheck: PropTypes.func.isRequired,
};

TableFailedTransactionsTableItem.defaultProps = {
  Id: null,
  Paid: null,
  Status: null,
  CurrencyCode: null,
  AmountGross: null,
};

export default TableFailedTransactionsTableItem;
